const levels = [
  {
    name: "justify-content 1",
    event: "B16IJQN7",
    instructions: `<p>Good day, my lord. <br>Welcome to the Knights of the Flexbox table. A game where you can help Sir Frederic Flexbox and his friends to uncover the treasures hidden in the Tailwind CSS dungeons.<br>
    You can navigate the knight through the dungeon by changing his position within the dungeon using Flexbox and <a href="https://tailwindcss.com" target="_blank">Tailwind CSS</a>.</p>
    <p>
        Use <code>justify-start</code> to justify items against the start of the container’s main axis.<br>
        Use <code>justify-center</code> to justify items along the center of the container’s main axis.<br>
        Use <code>justify-end</code> to justify items against the end of the container’s main axis.<br>
        Use <code>justify-between</code> to justify items along the container’s main axis such that there is an equal amount of space between each item.<br>
        Use <code>justify-around</code> to justify items along the container’s main axis such that there is an equal amount of space on each side of each item.<br>
    </p>
    <p>Give it a try by typing <code>justify-end</code> into the input below.</p>
    `,
    solvedInstructions: `<p>Hooray, you did it! Press Enter or click the button to go to the next dungeon</p>`,
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["dirt/t.png", "dirt/tr.png", "c2.png", "c2.png", "r.png"],
      ["dirt/c.png", "dirt/r.png", "b.png", "b.png", "br.png"],
    ],
    knights: ["green"],
    classes: "justify-end",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "justify-content 2",
    event: "0AFNLSCX",
    instructions: `<p>This time there are two knights. Sir Frederic, and his buddy Gregor Grid. Move the knights to the treasure chest with their color.</p>
    <p>As a reminder, these are the available Tailwind CSS classes:</p>
    <p>
        Use <code>justify-start</code> to justify items against the start of the container’s main axis.<br>
        Use <code>justify-center</code> to justify items along the center of the container’s main axis.<br>
        Use <code>justify-end</code> to justify items against the end of the container’s main axis.<br>
        Use <code>justify-between</code> to justify items along the container’s main axis such that there is an equal amount of space between each item.<br>
        Use <code>justify-around</code> to justify items along the container’s main axis such that there is an equal amount of space on each side of each item.<br>
    </p>
    `,
    knights: ["green", "yellow"],
    map: [
      ["tl.png", "t3.png", "t4.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c3.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["dirt/t.png", "dirt/tr.png", "c2.png", "dirt/tl.png", "dirt/t.png"],
      ["dirt/c.png", "dirt/r.png", "b.png", "dirt/l.png", "dirt/c.png"],
    ],
    classes: "justify-center",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    event: "H7KAJKPE",
    name: "justify-content 3",
    instructions: `<p>This time there are three knights. Help them find their treasures. Do you need to lookup the justify-content classes again? Take a look at the <a href="https://tailwindcss.com/docs/justify-content" target="_blank">Tailwind CSS documentation</a>.</p>
    `,
    knights: ["green", "yellow", "red"],
    map: [
      ["tl.png", "t3.png", "t4.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c3.png", "c2.png", "r.png"],
      ["dirt/t.png", "dirt/t.png", "dirt/t.png", "dirt/t.png", "dirt/t.png"],
      ["dirt/c.png", "dirt/c.png", "dirt/c.png", "dirt/c.png", "dirt/c.png"],
    ],
    classes: "justify-around",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "justify-content 4",
    event: "SM0ISENJ",
    instructions: `This time, the three knights need to find their chests as they have equal space between them. Do you need to lookup the justify-content classes again? Take a look at the <a href="https://tailwindcss.com/docs/justify-content" target="_blank">Tailwind CSS documentation</a>.</p>`,
    knights: ["green", "yellow", "red"],
    map: [
      ["tl.png", "t3.png", "t4.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c3.png", "c2.png", "r.png"],
      ["l.png", "c3.png", "c3.png", "c3.png", "r.png"],
      ["dirt/t.png", "dirt/t.png", "dirt/t.png", "dirt/t.png", "dirt/t.png"],
      ["dirt/c.png", "dirt/c.png", "dirt/c.png", "dirt/c.png", "dirt/c.png"],
    ],
    classes: "justify-between",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "align-items 1",
    event: "VIDGHGQZ",
    instructions: `<p>The chest is now in the center of the dungeon. In order for the knights to reach them, we need to use the "align-items" CSS classes.</p>
    <p>These are the available Tailwind CSS classes to align items in a flexbox:</p>
    <p>
        Use <code>items-start</code>  to align items to the start of the container’s cross axis.<br>
        Use <code>items-center</code> to align items along the center of the container’s cross axis.<br>
        Use <code>items-end</code> to align items to the end of the container’s cross axis.<br>
        Use <code>items-baseline</code> to align items along the container’s cross axis such that all of their baselines align.<br>
        Use <code>items-stretch</code> to stretch items to fill the container’s cross axis.<br>
    </p>
    <p>Give it a try, and see how it works!</p>`,
    knights: ["green"],
    map: [
      ["tl.png", "t3.png", "t4.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c3.png", "c2.png", "r.png"],
      ["l.png", "c3.png", "c3.png", "c3.png", "r.png"],
      ["dirt/t.png", "dirt/t.png", "dirt/t.png", "dirt/t.png", "dirt/t.png"],
      ["dirt/c.png", "dirt/c.png", "dirt/c.png", "dirt/c.png", "dirt/c.png"],
    ],
    classes: "items-center",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "align-items 2",
    event: "6MAZHYNJ",
    instructions: `<p>This chest is once again in the center of the dungeon, but now only horizontally, but also vertically. Use a combination of <code>justify-items</code> and <code>align-items</code> to reach this chest.</p>
    <p>These are the available Tailwind CSS classes to align items in a flexbox:</p>
    <p>
        Use <code>items-start</code>  to align items to the start of the container’s cross axis.<br>
        Use <code>items-center</code> to align items along the center of the container’s cross axis.<br>
        Use <code>items-end</code> to align items to the end of the container’s cross axis.<br>
        Use <code>items-baseline</code> to align items along the container’s cross axis such that all of their baselines align.<br>
        Use <code>items-stretch</code> to stretch items to fill the container’s cross axis.<br>
    </p>
    <p>Give it a try, and see how it works!</p>`,
    knights: ["green"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["dirt/t.png", "dirt/tr.png", "c2.png", "c2.png", "r.png"],
      ["dirt/c.png", "dirt/r.png", "b.png", "b.png", "br.png"],
    ],
    classes: "items-center justify-center",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "align-items 3",
    event: "YJMUYNWS",
    instructions: `<p>Help the three knights find their treasures using a combination of <code>justify-items</code> and <code>align-items</code>.<br>
    Here are some helpful links in case you run into trouble:<br>
    <a href="https://tailwindcss.com/docs/justify-content" target="_blank">justify-content documentation</a><br>
    <a href="https://tailwindcss.com/docs/align-items" target="_blank">align-items documentation</a>
    </p>
    `,
    knights: ["green", "yellow", "red"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    classes: "justify-around items-end",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "flex-direction 1",
    event: "PUK0JYVI",
    instructions: `<p>The chests are now in reverse order of the knights. To change the direction of our knights, you have to use the <code>flex-direction</code> CSS classes. These classes control the direction of flex items.</p>
    <p>These are the available Tailwind CSS classes:</p>
    <p>
        Use <code>flex-row</code> to position flex items horizontally in the same direction as text.<br>
        Use <code>flex-row-reverse</code> to position flex items horizontally in the opposite direction.<br>
        Use <code>flex-col</code> to position flex items vertically.<br>
        Use <code>flex-col-reverse</code> to position flex items vertically in the opposite direction.<br>
    </p>
    <p>Go ahead and try it out!</p>`,
    knights: ["green", "yellow", "red"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    classes: "flex-row-reverse",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "flex-direction 2",
    event: "FU34AOS0",
    instructions: `<p>Once again use the <code>flex-direction</code> CSS classes to help the knights reach their chests.</p>
    <p>These are the available Tailwind CSS classes:</p>
    <p>
        Use <code>flex-row</code> to position flex items horizontally in the same direction as text.<br>
        Use <code>flex-row-reverse</code> to position flex items horizontally in the opposite direction.<br>
        Use <code>flex-col</code> to position flex items vertically.<br>
        Use <code>flex-col-reverse</code> to position flex items vertically in the opposite direction.<br>
    </p>`,
    knights: ["green", "yellow", "red"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    classes: "flex-col",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "flex-direction 3",
    event: "VUKFTIHS",
    instructions: `<p>This time, you need to use a combination of all three Tailwind classes that you've learned about.</p>
    <p>
    <a href="https://tailwindcss.com/docs/align-items" target="_blank">justify-content documentation</a><br>
    <a href="https://tailwindcss.com/docs/justify-content" target="_blank">align-items documentation</a><br>
    <a href="https://tailwindcss.com/docs/flex-direction" target="_blank">flex-direction documentation</a>
    </p>`,
    knights: ["green", "yellow", "red"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    classes: "flex-col-reverse items-end justify-center",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "order 1",
    event: "EMIFTKNK",
    instructions: `<p>In some situations, it might not be enought to be able to reverse the flexbox item order.
    Here, we need to change the order of the knights to make them reach their chests.<br>
    To make this work, we can apply an individual <code>order</code> CSS classes to the knights to change their order within a flexbox container. The default order is <code>0</code>.</p>
    <p>These are the available Tailwind CSS classes:</p>
    <p>
        Use <code>order-{order}</code> (1-12) to render flex and grid items in a different order than they appear in the DOM.<br>
        Use <code>order-first</code> to force the first order of an item. This sets the order to -9999.<br>
        Use <code>order-last</code> to force the first order of an item. This sets the order to 9999.<br>
        Use <code>order-none</code> to reset the order back to 0.<br>
    </p>`,
    knights: ["green", "yellow", "red"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    chestClasses: {
      yellow: "order-2",
    },
    classes: "",
    applyClasses: "yellow",
    before: '<div class="yellow-knight ',
    after: "></div>",
  },
  {
    name: "order 2",
    event: "AL9WV2V0",
    instructions: `<p>Once again, use one of the available <code>order</code> CSS classes to let the red knight reach the chest.</p>
    <p>These are the available Tailwind CSS classes:</p>
    <p>
        Use <code>order-{order}</code> (1-12) to render flex and grid items in a different order than they appear in the DOM.<br>
        Use <code>order-first</code> to force the first order of an item. This sets the order to -9999.<br>
        Use <code>order-last</code> to force the first order of an item. This sets the order to 9999.<br>
        Use <code>order-none</code> to reset the order back to 0.<br>
    </p>`,
    knights: ["green", "green", "green", "red", "green"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    chestClasses: {
      red: "order-first",
    },
    classes: "",
    applyClasses: "red",
    before: '<div class="red-knight ',
    after: "></div>",
  },
  {
    name: "align-self 1",
    event: "YPQDWWA0",
    instructions: `<p>In this dungeon, the yellow knight needs to be in the center of the dungeon. You can modify the position of individual flex items, by using the <code>align-self</code> CSS classes.</p>
    <p>These are the available Tailwind CSS classes:</p>
    <p>
        Use <code>self-auto</code> to align an item based on the value of the container’s <code>align-items</code> property.<br>
        Use <code>self-start</code>  to align an item to the start of the container’s cross axis, despite the container’s <code>align-items</code> value.<br>
        Use <code>self-end</code> to align an item to the end of the container’s cross axis, despite the container’s <code>align-items</code> value.<br>
        Use <code>self-center</code> to align an item along the center of the container’s cross axis, despite the container’s <code>align-items</code> value.<br>
        Use <code>self-stretch</code> to stretch an item to fill the container’s cross axis, despite the container’s <code>align-items</code> value.<br>
    </p>
    <p>Try moving the yellow knight to the center of the dungeon:</p>`,
    knights: ["green", "green", "yellow", "green", "green"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    chestClasses: {
      yellow: "self-center",
    },
    classes: "",
    applyClasses: "yellow",
    before: '<div class="yellow-knight ',
    after: "></div>",
  },
  {
    name: "align-self 2",
    event: "MDOX45VT",
    instructions: `<p>Now try using a combination of the <code>order</code> and <code>align-self</code> classes.</p>`,
    knights: ["red", "green", "red", "green", "green"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    chestClasses: {
      red: "self-end order-2",
    },
    classes: "",
    applyClasses: "red",
    before: '<div class="red-knight ',
    after: "></div>",
  },
  {
    name: "wrap 1",
    event: "F06WVFY5",
    instructions: `<p>Uh oh, there are way too many knights in one row of the dungeon! They are stepping on each others toes!<br>
    Using <code>flex-wrap</code>, you can control how flex items should behave in such a situation.<br>
    The available Tailwind CSS classes are:</p>
    <p>
        Use <code>flex-nowrap</code> to prevent flex items from wrapping, causing inflexible items to overflow the container if necessary.<br>
        Use <code>flex-wrap</code> to allow flex items to wrap.<br>
        Use <code>flex-wrap-reverse</code> to wrap flex items in the reverse direction.<br>
    </p>
    <p>Try wrapping the knights, so they each can find their chest.</p>`,
    knights: ["green", "green", "green", "green", "green", "yellow", "red"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    chestClasses: {},
    knightClasses: "content-start",
    classes: "flex-wrap content-start",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "wrap 2",
    event: "LP63RFBS",
    instructions: `<p>Try reaching these chests with a combination of <code>flex-wrap</code> and <code>justify-items</code>.</p>`,
    knights: ["green", "green", "green", "green", "green", "yellow", "red"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    chestClasses: {},
    knightClasses: "content-start",
    classes: "content-start flex-wrap-reverse justify-end",
    before: '<div id="dungeon" class="flex ',
    after: "></div>",
  },
  {
    name: "align-content 1",
    event: "CFCBRWWR",
    instructions: `<p>When flex items are wrapped around multiple rows, you can use <code>align-content</code> classes to control how you want to place the items.
    
    The available Tailwind CSS classes are:</p>
    <p>
        Use <code>content-start</code> to pack rows in a container against the start of the cross axis.<br>
        Use <code>content-center</code> to pack rows in a container in the center of the cross axis.<br>
        Use <code>content-end</code> to pack rows in a container against the end of the cross axis.<br>
        Use <code>content-between</code> to distribute rows in a container such that there is an equal amount of space between each line.<br>
        Use <code>content-around</code> to distribute rows in a container such that there is an equal amount of space around each line.<br>
        Use <code>content-evenly</code> to distribute rows in a container such that there is an equal amount of space around each item, but also accounting for the doubling of space you would normally see between each item when using content-around.<br>
    </p>
    `,
    knights: ["green", "green", "green", "green", "green", "red"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c3.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    chestClasses: {},
    knightClasses: "content-start flex-wrap",
    rawKnightClasses: "flex-wrap",
    classes: "content-between flex-wrap",
    before: '<div id="dungeon" class="flex',
    after: "></div>",
  },
  {
    name: "align-content 1",
    event: "KODVWVIU",
    instructions: `<p>Use a combination of the Tailwind CSS classes you learnt, to retrieve the chests from the dungeon.</p>`,
    knights: ["green", "yellow", "green", "yellow", "green", "red"],
    map: [
      ["tl.png", "t.png", "t.png", "t.png", "tr.png"],
      ["l.png", "c2.png", "c.png", "c2.png", "r.png"],
      ["l.png", "c2.png", "c2.png", "c_gutter.png", "r.png"],
      ["l.png", "c.png", "c2.png", "c2.png", "r.png"],
      ["bl.png", "b.png", "b.png", "b.png", "br.png"],
    ],
    chestClasses: {},
    knightClasses: "content-start",
    rawKnightClasses: "flex-wrap",
    classes: "flex-wrap-reverse content-center justify-center",
    before: '<div id="dungeon" class="flex',
    after: "></div>",
  },
  {
    name: "you-won",
    instructions: `<p>Congratulations! You led Frederic Flexbox and his friends to all treasures within the Flexbox dungeons!</p>`,
  },
];

export default levels;